import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import "jquery-validation";
import "jquery-validation/dist/localization/messages_fr";

$(document).ready(function ($) {
  let imcData;
  fetch("templates/assets/js/calculateurs/imc_fr.json")
    .then((response) => response.json())
    .then((data) => (imcData = data))
    .catch((error) => console.error("Erreur:", error));
  let $ageMonth = $(".form-group--month,.age__and,.age__infos-month");
  let $ageSwitch = $(".age-switch");
  let $inputAgeMonth = $(".input__age--month");
  let $inputAgeYear = $(".input__age--year");
  let $sexGirl = $(".sexe__label--girl");
  let $sexBoy = $(".sexe__label--boy");

  // Switch age si inférieur à 18 ans
  $(".slider").on("click", () => {
    $(".definitions__adult").hide();
    $(".defintions__child").show();
    $(".graph__adult").hide();
    $(".graph__child").show();
    $(".form-group--name").slideToggle(200);

    $sexGirl.text("Fille");
    $sexBoy.text("Garçon");
    $inputAgeYear.val("");
    $ageSwitch.toggleClass("old");
    $ageMonth.toggleClass("active");
    if (!$ageSwitch.hasClass("old")) {
      $(".definitions__adult").show();
      $(".defintions__child").hide();
      $(".graph__adult").show();
      $(".graph__child").hide();
      $inputAgeMonth.val("");
      $sexGirl.text("Femme");
      $sexBoy.text("Homme");
    }
  });

  $(".sexe__label").on("click", function () {
    $(".sexe__label").removeClass("active");
    $(this).addClass("active");
  });

  $.validator.addMethod(
    "atLeastOne",
    function (value, element, params) {
      var otherInputValue = $(params).val();
      return value || otherInputValue; // Si l'une des valeurs est présente, retournez `true`.
    },
    "Age obligatoire"
  );

  $("#calculator__imc").validate({
    rules: {
      input__taille: {
        required: true,
      },
      sexe: {
        required: true,
      },
      input__age__year: {
        atLeastOne: ".input__age--month",
      },
      input__age__month: {
        atLeastOne: ".input__age--year",
      },
      input__poids: {
        required: true,
      },
    },
    messages: {
      sexe: {
        required: "Genre obligatoire",
      },
      input__poids: {
        required: "Poids obligatoire",
      },
      input__taille: {
        required: "Taille obligatoire",
      },
    },

    errorElement: "span",
    highlight: function (element, errorClass) {
      $(element).addClass("invalid");
    },
    unhighlight: function (element, errorClass) {
      $(element).removeClass("invalid");
    },
    submitHandler: function (form, event) {
      event.preventDefault(); // Empêche la soumission réelle du formulaire et le rechargement de la page
      imc();
    },
  });

  function imc() {
    let resultIndice = $(".graph__result").find(".result__indice span");
    let ageYear = parseInt($(".input__age--year").val()) || 0;
    let ageMonth = parseInt($(".input__age--month").val()) || 0;
    let poids = parseFloat($(".input__poids").val());
    let taille = parseFloat($(".input__taille").val()) / 100;
    let sex = $("input[name='sexe']:checked").val();
    let age = (ageYear * 12 + ageMonth) / 10;
    let imc = poids / (taille * taille);

    const majeur = (18 * 12) / 10;
    const majeurPlus = (19 * 12) / 10;

    function setCutoffs($age, $sex) {
      let { overweight, obesity } = imcData[$age][$sex];
      let weightLessValue = overweight * (taille * taille);
      let weightPlusValue = obesity * (taille * taille);

      $(".value__overweight").text(overweight);
      $(".value__obesity").text(obesity);
      $(".value__weight--less").text(weightLessValue.toFixed(1));
      $(".value__weight--plus").text(`${weightPlusValue.toFixed(1)}Kg`);

      let cutoffGap = obesity - overweight;
      let cutoffUnity = cutoffGap / 80;
      let cutoffStart = cutoffUnity * 5;
      let cutoffDegrees = 144 / cutoffGap;

      let degreesStartChild = (overweight - cutoffStart) * cutoffDegrees;

      if ((age < majeur && age > 0) || (age >= majeur && age < majeurPlus)) {
        switch (true) {
          case imc >= overweight && imc <= obesity:
            resultIndice
              .removeClass()
              .addClass("result__indice--C")
              .text("normal");
            break;
          case imc < overweight:
            resultIndice
              .removeClass()
              .addClass("result__indice--A")
              .text("Maigreur");
            break;
          case imc > obesity && imc < obesity + cutoffStart * 2:
            resultIndice
              .removeClass()
              .addClass("result__indice--D")
              .text("Risque Surpoids");
            break;
          default:
            resultIndice
              .removeClass()
              .addClass("result__indice--E")
              .text("Surpoids");
        }
        return imc * cutoffDegrees - degreesStartChild;
      } else {
        switch (true) {
          case imc < 16.5:
            resultIndice
              .removeClass()
              .addClass("result__indice--A")
              .text("Dénutrition");
            break;
          case imc >= 16.5 && imc < 18.5:
            resultIndice
              .removeClass()
              .addClass("result__indice--B")
              .text("Maigreur");
            break;
          case imc >= 18.5 && imc <= 25:
            resultIndice
              .removeClass()
              .addClass("result__indice--C")
              .text("Normal");
            break;
          case imc > 25 && imc <= 30:
            resultIndice
              .removeClass()
              .addClass("result__indice--D")
              .text("Surpoids");
            break;
          case imc > 30 && imc <= 35:
            resultIndice
              .removeClass()
              .addClass("result__indice--E")
              .text("Obesité Classe 1");
            break;
          case imc > 35 && imc <= 40:
            resultIndice
              .removeClass()
              .addClass("result__indice--F")
              .text("Obesité Classe 2");
            break;
          default:
            resultIndice
              .removeClass()
              .addClass("result__indice--G")
              .text("Obésité Classe 3");
        }
        return imc * 6 - 79;
      }
    }

    let degrees;
    if (age < majeur && age > 0) {
      degrees = setCutoffs(age, sex);
      $(".graph__adult").hide();
      $(".graph__child").show();
      $(".definitions__adult").hide();
      $(".defintions__child").show();
    } else if (age >= majeur && age < majeurPlus) {
      degrees = setCutoffs("21.6", sex);
      $(".graph__adult").hide();
      $(".graph__child").show();
      $(".definitions__adult").hide();
      $(".defintions__child").show();
    } else {
      degrees = setCutoffs("21.7", sex);
      $(".graph__adult").show();
      $(".graph__child").hide();
      $(".definitions__adult").show();
      $(".defintions__child").hide();
    }

    degrees = Math.max(0, Math.min(degrees, 180));

    function formatValue(value) {
      return parseFloat(value.toFixed(1));
    }

    // let objImc = { value: 0 };

    $(".value__indice").text(formatValue(imc));

    anime({
      targets: ".jauge",
      rotate: degrees,
      duration: 3000,
      easing: "easeInOutElastic(1, .8)",
    });

    // let tl = anime.timeline({
    //   easing: "easeOutExpo",
    //   duration: 1500,
    // });

    // tl.add(
    //   {
    //     targets: ".jauge",
    //     rotate: degrees,
    //     duration: 750,
    //     easing: "easeInOutQuad",
    //   },
    //   0
    // ).add(
    //   {
    //     targets: objImc,
    //     value: imc,
    //     // round: 1,
    //     easing: "linear",
    //     duration: 750,
    //     update: function () {
    //       $(".value__indice").text(formatValue(objImc.value));
    //     },
    //     complete: function () {
    //       $(".value__indice").text(formatValue(imc));
    //     },
    //   },
    //   0
    // );
    //
  }
});
