jQuery(document).ready(function () {
  let subBtn = $(".portions--sub");
  let addBtn = $(".portions--add");
  let portionValue = $(".portions__value");
  let ingredientsQty = $(".ingredient__qty");
  let portionsNumber = $(".portions__number__value");
  let portionsName = $(".portions__number__name");
  let portionsInfosInitial = $(".portions__infos--initial");
  let portionsInfosTotal = $(".portions__infos--total");
  let nutritionValues = $(".item__result");
  let coeffPortions = parseFloat($(".coeff__portions").val());
  let nutriTotalBtn = $(".nutri__total");
  let nutriInitialBtn = $(".nutri__initial");
  let nutriRecipePortions = $(".recipe__nutri .recipe__portions");

  let initialPortionValue = parseFloat(portionValue.text());

  // Initialiser la valeur globale des portions
  let globalPortionsValue = parseFloat(portionValue.text());
  // Initialiser le texte de portionsName qui est présent dans ingrédient seulement pour éviter la duplication du texte dans les valeurs nutris
  let originalPortionsName = $(".recipe__ingredients")
    .find(portionsName)
    .text();

  // GCD Function
  function gcd(a, b) {
    return b ? gcd(b, a % b) : a;
  }
  // Cette fonction trouve le dénominateur le plus proche pour un nombre décimal
  function findClosestFraction(decimal) {
    const fractions = [2, 3, 4, 8]; // Vous pouvez ajouter d'autres fractions ici si vous le souhaitez
    let closestFraction = 1;
    let minDifference = Math.abs(1 - decimal);
    for (let fraction of fractions) {
      let difference = Math.abs(1 / fraction - decimal);
      if (difference < minDifference) {
        minDifference = difference;
        closestFraction = fraction;
      }
    }
    return closestFraction;
  }

  function updateValues() {
    ingredientsQty.each(function () {
      let initialQty = parseFloat($(this).data("initial-qty"));
      let newValue = (initialQty * globalPortionsValue) / initialPortionValue;

      if (newValue < 1) {
        let closestFraction = findClosestFraction(newValue);
        if (closestFraction !== 1) {
          newValue = "1/" + closestFraction;
        } else {
          newValue = 1;
        }
      } else {
        newValue = Math.floor(newValue);
      }

      $(this).text(newValue); // Cette ligne était manquante.
    });

    let newPortionsNumber = globalPortionsValue * coeffPortions;
    portionsNumber.text(newPortionsNumber);

    if (newPortionsNumber > 1) {
      portionsName.text(originalPortionsName + "s");
    } else {
      portionsName.text(originalPortionsName);
    }

    nutritionValues.each(function () {
      let initialValue = parseFloat($(this).data("initial-value"));
      if (nutriTotalBtn.hasClass("active")) {
        $(this).text(initialValue * globalPortionsValue);
      } else {
        $(this).text(initialValue);
      }
    });

    // Mise à jour de la valeur des portions pour tous les éléments
    portionValue.text(globalPortionsValue);
  }

  // Event Handlers
  subBtn.click(function () {
    if (globalPortionsValue > 1) {
      globalPortionsValue -= 1;
      updateValues();
    }
  });

  addBtn.click(function () {
    globalPortionsValue += 1;
    updateValues();
  });

  nutriTotalBtn.click(function () {
    $(this).addClass("active");
    nutriInitialBtn.removeClass("active");
    nutriRecipePortions.css("display", "flex");
    portionsInfosTotal.show();
    portionsInfosInitial.hide();
    updateValues();
  });

  nutriInitialBtn.click(function () {
    $(this).addClass("active");
    nutriTotalBtn.removeClass("active");
    nutriRecipePortions.hide();
    portionsInfosTotal.hide();
    portionsInfosInitial.show();
    updateValues();
  });

  // Initialize
  ingredientsQty.each(function () {
    $(this).data("initial-qty", $(this).text());
  });

  nutritionValues.each(function () {
    $(this).data("initial-value", $(this).text());
  });

  // Update values according to initial portion
  updateValues();

  // =======================================
  if (window.innerWidth <= 880) {
    $(".recipe__nutri .recipe__title").on("click", function () {
      $(".recipe__nutri__wrapper").slideToggle(100);
    });
  }
  $(".ingredient__list").on("click", function () {
    $(this).toggleClass("active");
    $(this).find(".ingredient__checkbox").toggleClass("checked");
  });
});
