import Swiper from "swiper/bundle";
import "swiper/swiper.scss";
// import "swiper/css/bundle";

jQuery(document).ready(function ($) {
  const swiper = new Swiper(".swiper", {
    slidesPerView: 3,
    // slidesPerGroup: 4,
    spaceBetween: 4,
    loop: true,
    // freeMode: true,
    speed: 900,
    loopedSlides: 10,
    centeredSlides: true,
    initialSlide: 1,
    autoplay: false,
    // allowTouchMove: false,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    breakpoints: {
      280: {
        slidesPerView: 1,
      },
      360: {
        slidesPerView: 2,
      },
      550: {
        slidesPerView: 3,
      },
    },
  });

  // $(".swiper__realisations .swiper").hover(
  //   function () {
  //     swiper.autoplay.stop();
  //   },
  //   function () {
  //     swiper.autoplay.start();
  //   }
  // );
});
